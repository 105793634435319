<template>
  <div>
    <!-- ==== faq section start ==== -->
    <section style="margin-top: 131px" class="faq__alt section__space__bottom">
      <div class="container">
        <div class="faq__alt__area">
          <div class="section__header section__space__bottom">
            <h2 class="
                text-center
                wow
                animate__animated animate__fadeInUp
                content__space
              ">
              Privacy Policy
            </h2>
          </div>
          <div class="faq__tabs__wrapper">
            <div class="accordion accordion-flush wow fadeInUp" data-wow-duration="0.6s" id="accordionFlushExample">
              <tab-content visible="true">
                <template v-slot:heading>Information We Collect</template>
                <template v-slot:content>
                  <p>
                    When you use the Facebook Login feature on ShipIndia, we may collect the following information from
                    your Facebook account:
                  </p>
                  <ul>
                    <li>Basic Information: Your name, profile picture, and email address.</li>
                    <li>Public Profile Information: Your Facebook username, age range, gender, and other public
                      information available on your Facebook profile.</li>
                    <li>Friends List: We may collect a list of your Facebook friends who are also using ShipIndia.</li>
                    <li>Permissions: We may request additional permissions from you to access certain information or
                      perform specific actions on your behalf. We will clearly state the purpose for which we need these
                      permissions.</li>
                  </ul>
                  <p>
                    Please note that we only collect the information necessary to provide our services, and we do not have
                    access to your Facebook password.
                  </p>
                </template>
              </tab-content>
              <tab-content>
                <template v-slot:heading> Use of Information</template>
                <template v-slot:content>
                  <p>
                    We use the information collected through Facebook Login to:
                  </p>
                  <ul>
                    <li>Create and manage your ShipIndia account.</li>
                    <li>Verify your identity and ensure the security of your account.</li>
                    <li>Provide personalized content, recommendations, and features.</li>
                    <li>Connect you with your Facebook friends who are also using ShipIndia.</li>
                    <li>Facilitate social interactions on our platform, such as sharing content or inviting friends to
                      join ShipIndia.</li>
                    <li>Communicate with you, respond to your inquiries, and provide customer support.</li>
                  </ul>
                </template>
              </tab-content>
              <tab-content>
                <template v-slot:heading>Information Sharing and Disclosure</template>
                <template v-slot:content>
                  <p>
                    We may share your information with third parties in the following circumstances:
                  </p>
                  <ul>
                    <li>Service Providers: We may engage trusted third-party service providers to assist us in providing
                      and improving our services. These providers will have access to your information only to perform
                      tasks on our behalf and are obligated not to disclose or use it for any other purpose.</li>
                    <li>Legal Compliance: We may disclose your information if required by law, regulation, legal process,
                      or governmental request.</li>
                    <li>Business Transfers: In the event of a merger, acquisition, or sale of all or a portion of our
                      assets, your information may be transferred to the acquiring entity.</li>
                    <li>Aggregated or Anonymized Data: We may share aggregated or anonymized data that does not personally
                      identify you for various purposes, including analytics, research, and marketing.</li>
                  </ul>
                </template>
              </tab-content>
              <tab-content>
                <template v-slot:heading>Facebook Data Deletion Instructions URL</template>
                <template v-slot:content>
                  <p>
                    If you want to delete your activities for ShipIndia Login App, you can remove your information by
                    following these steps:
                  </p>
                  <ul>
                    <li>Go to your Facebook Account's Settings & Privacy. Click "Settings".</li>
                    <li>Look for "Apps and Websites" and you will see all of the apps and websites linked to your Facebook
                      account.</li>
                    <li>Search and click "ShipIndia Login" in the search bar.</li>
                    <li>Scroll and click "Remove".</li>
                  </ul>
                  <p>
                    Congratulations, you have successfully removed your app activities.
                    By using the Facebook Login feature on ShipIndia, you signify your acceptance of this Privacy Policy.
                    If you do not agree with this policy, please refrain from using our services.
                  </p>
                </template>
              </tab-content>
              <tab-content>
                <template v-slot:heading> Data Retention</template>
                <template v-slot:content>
                  <p>
                    We retain your information for as long as necessary to fulfill the purposes outlined in this Privacy
                    Policy, unless a longer retention period is required or permitted by law. When we no longer require
                    your information, we will securely delete or anonymize it.
                  </p>
                 
                </template>
              </tab-content>
              <tab-content>
                <template v-slot:heading> Security</template>
                <template v-slot:content> <p>
                    We implement appropriate technical and organizational measures to protect your information from
                    unauthorized access, disclosure, alteration, or destruction. However, please note that no method of
                    transmission over the internet or electronic storage is 100% secure, and we cannot guarantee its
                    absolute security.
                  </p>
                </template>
              </tab-content>
              <tab-content>
                <template v-slot:heading> Your Choices</template>
                <template v-slot:content>
                  <p>
                    You have certain rights and choices regarding the information we collect from you, including:
                  </p>
                  <ul>
                    <li>Access and Correction: You can access and update certain information we have about you by logging
                      into your ShipIndia account.</li>
                    <li>Account Deletion: You can delete your ShipIndia account at any time by contacting us using the
                      information provided below. Please note that deleting your account will permanently remove your data
                      from our systems.</li>
                    <li>Opt-Out: You can opt-out of certain communications and marketing materials by following the
                      instructions provided in the relevant communication or contacting us.</li>
                  </ul>
                </template>
              </tab-content>
              <tab-content>
                <template v-slot:heading> Children's Privacy</template>
                <template v-slot:content>
                  <p>
                    ShipIndia does not knowingly collect personal information from individuals under the age of 16. If we
                    become aware that we have collected personal information from a child without parental consent, we
                    will take steps to delete the information as soon as possible.
                  </p>
                </template>
              </tab-content>
              <tab-content>
                <template v-slot:heading> Changes to this Privacy Policy</template>
                <template v-slot:content><p>
                    We may update this Privacy Policy from time to time to reflect changes in our practices or legal
                    requirements. We will notify you of any material changes by posting the updated Privacy Policy on our
                    website or through other communication channels. We encourage you to review this policy periodically
                    for the latest information.
                  </p>
                </template>
              </tab-content>
              <tab-content>
                <template v-slot:heading> Contact Us</template>
                <template v-slot:content>
                  <p>
                    If you have any questions, concerns, or requests regarding this Privacy Policy or the handling of your
                    information, please contact us at:
                  </p><br/> 
                  <p>
                    ShipIndia Privacy Team<br>
                    Email: privacy@shipindia.com
                  </p><br>
                  <p>
                    Please allow us a reasonable time to respond to your inquiries and requests.
                  </p>
                </template>
              </tab-content>

            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- ==== #faq section end ==== -->
  </div>
</template>

<script>
import { WOW } from "wowjs";
import shapeTwo from "../assets/images/feature/shape-two.png";
import TabContent from "../components/TabContent.vue";
export default {
  components: { TabContent },
  data() {
    return {
      shapeTwo: shapeTwo,
    };
  },
  mounted() {
    new WOW().init();
  },
};
</script>

<style></style>
